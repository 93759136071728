/**
 * 路由配置页面，所有的路由统一在这里配置，统一管理
 *
 * 格式：
 * 一级菜单没有component组件，如果要默认显示组件，在children种配置默认组件
 * 配置方式如下：
 * 无children路由配置：
 * （单页面配置，如果登录页面，404页面）
 * {
        name: 'login',                                          # 第一级导航名称
        path: '/login',                                         # 第一级导航路径,必须有左斜线 “/”
        meta: {                                                 # 路由元数据
            icon: ''                                            # 一级导航icon图标，单页面可以没有icon图标
        },
        component: () => import ('@/pages/agents/login.vue')    # 单页面组件导入
    }
 * 有children路由配置：
    {
        name: '商户管理',                                       # 第一级导航名称
        path: '/merchant',                                      # 第一级导航路径,必须有左斜线 “/”
        meta: {                                                 # 路由元数据
            icon: 'icon-shanghuguanli',                         # 一级导航icon图标
            permission: []                                      # 一级导航权限列表
        },
        component: () => import ('@/pages/layout/layout.vue'),  # layout布局组件导入
        children: [                                             # 定义children列表
            {
                name: '新增商户',                                # 二级导航名称
                path: 'new',                                     # 二级导航路径，没有斜线
                meta: {                                          # 二级导航元数据
                    transition: 'slide-right'                    # 二级导航载入动画
                    permission: []                               # 二级导航权限列表
                },
                components: {
                    merchant_new: () => import ('@/pages/merchant/new.vue') #children子组件导入
                                                                            #这里有个很重要的注意点：每个子组件的components定义的时候都要注明router-view的视图命名
                                                                                                    视图命名规则：父组件的路径（path）去掉斜线+‘_’+子组件路径（path）
                }
            }
        ]
    }
 */

const routes = [
  {
    name: "404",
    path: "/404",
    meta: {
      icon: "",
    },
    component: () => import("@/pages/error/404.vue"),
  },
  {
    name: "login",
    path: "/login",
    meta: {
      icon: "",
    },
    component: () => import("@/pages/admin/login.vue"),
  },
  {
    name: "resetPassword",
    path: "/resetPassword",
    meta: {
      icon: "",
    },
    component: () => import("@/pages/admin/resetPassword.vue"),
  },
  {
    name: "首页",
    path: "/",
    meta: {
      icon: "icon-shouye",
      auth: "auth",
      permission: [],
    },
    redirect: "/dashboard",
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "经营数据",
        path: "dashboard",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          dashboard: () => import("@/pages/home/dashboard.vue"),
        },
      },
    ],
  },
  {
    name: "商户管理",
    path: "/users",
    meta: {
      icon: "icon-shanghuguanli",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "新增商户",
        path: "new",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          users_new: () => import("../pages/merchant/new.vue"),
        },
      },
      {
        name: "商户列表",
        path: "list",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          users_list: () => import("../pages/merchant/list.vue"),
        },
      },
      {
        name: "商户流量余额",
        path: "traffic",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          users_traffic: () => import("../pages/merchant/traffic.vue"),
        },
      },
      {
        name: "服务费充值订单",
        path: "recharge",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          users_recharge: () => import("../pages/merchant/recharge.vue"),
        },
      },
      {
        name: "收银插件",
        path: "plugin",
        isshow: false,
        meta: {
          transition: "slide-right",
        },
        components: {
          users_plugin: () => import("../pages/merchant/shopPlugin/index.vue"),
        },
      },
    ],
  },
  {
    name: "代理商管理",
    path: "/agents",
    meta: {
      icon: "icon-dailishangguanli",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "代理商列表",
        path: "list",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          agents_list: () => import("../pages/agents/list.vue"),
        },
      },
      {
        name: "佣金管理",
        path: "commission",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          agents_commission: () => import("../pages/agents/commission.vue"),
        },
      },
      {
        name: "流量余额",
        path: "wallet",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          agents_wallet: () => import("../pages/agents/apiWallet/wallet.vue"),
        },
      },
      {
        name: "计费流水",
        path: "walletlogs",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          agents_walletlogs: () =>
            import("../pages/agents/apiWallet/walletlogs.vue"),
        },
      },
    ],
  },
  {
    name: "结算管理",
    path: "/settlement",
    meta: {
      icon: "icon-gaiicon-",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "结算列表",
        path: "list",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          settlement_list: () => import("../pages/settlement/list.vue"),
        },
      },
    ],
  },
  {
    name: "发票管理",
    path: "/invoice",
    meta: {
      icon: "icon-fapiaoguanli",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "开票记录",
        path: "record",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          invoice_record: () => import("../pages/invoice/record.vue"),
        },
      },
    ],
  },
  {
    name: "支付管理",
    path: "/pay",
    meta: {
      icon: "icon-zhifuguanli",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "支付方式",
        path: "methods",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          pay_methods: () => import("../pages/pay/methods.vue"),
        },
      },
      {
        name: "支付渠道",
        path: "channel",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          pay_channel: () => import("../pages/pay/channel.vue"),
        },
      },
    ],
  },
  {
    name: "终端管理",
    path: "/terminal",
    meta: {
      icon: "icon-zhongduanguanli",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "终端列表",
        path: "list",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          terminal_list: () => import("../pages/terminal/terminal.vue"),
        },
      },
    ],
  },
  {
    name: "设备管理",
    path: "/device",
    meta: {
      icon: "icon-shebeiguanli",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "设备分类",
        path: "category",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          device_category: () => import("../pages/device/category.vue"),
        },
      },
      {
        name: "设备类型",
        path: "type",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          device_type: () => import("../pages/device/type.vue"),
        },
      },
      {
        name: "设备列表",
        path: "record",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          device_record: () => import("../pages/device/record.vue"),
        },
      },
      {
        name: "二维码管理",
        path: "qrcode",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          device_qrcode: () => import("../pages/device/qrcode.vue"),
        },
      },
    ],
  },
  {
    name: "数据统计",
    path: "/statistics",
    meta: {
      icon: "icon-fengkongguanli",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "订单数据",
        path: "order",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          statistics_order: () => import("../pages/statistics/order.vue"),
        },
      },
      {
        name: "交易统计",
        path: "trade",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          statistics_trade: () => import("../pages/statistics/trade.vue"),
        },
      },
      {
        name: "代理月报明细",
        path: "merchantReport",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          statistics_merchantReport: () =>
            import("../pages/statistics/merchantReport.vue"),
        },
      },
      {
        name: "代理月报汇总",
        path: "agentReport",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          statistics_agentReport: () =>
            import("../pages/statistics/agentReport.vue"),
        },
      },
    ],
  },
  {
    name: "订单管理",
    path: "/orderReport",
    meta: {
      icon: "icon-dingdanguanli",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "订单记录",
        path: "order",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          orderReport_order: () =>
            import("../pages/order/orderReport/orderReport.vue"),
        },
      },
      {
        name: "退款记录",
        path: "refund",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          orderReport_refund: () =>
            import("../pages/order/orderRefund/orderRefund.vue"),
        },
      },
    ],
  },
  {
    name: "统计报表",
    path: "/statistic",
    meta: {
      icon: "icon-tongjibaobiao",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "历史统计",
        path: "history",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          statistic_history: () =>
            import("../pages/statistic/history/history.vue"),
        },
      },
      {
        name: "今日统计",
        path: "today",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          statistic_today: () =>
            import("../pages/statistic/today/today.vue"),
        },
      },
    ],
  },
  {
    name: "广告管理",
    path: "/advert",
    meta: {
      icon: "icon-ad",
      auth: "auth",
      permission: [],
    },
    component: () => import("@/pages/layout/layout.vue"),
    children: [
      {
        name: "广告列表",
        path: "list",
        isshow: true,
        meta: {
          transition: "slide-right",
        },
        components: {
          advert_list: () => import("../pages/advert/list.vue"),
        },
      },
    ],
  },
];

export default routes;
